import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useGuestSignup = () => {
    const [error, setError] = useState(null);
    const { dispatch } = useAuthContext();

    const guestSignup = async () => {
        setError(null);

        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/user/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({isGuest: true})
        });
        const json = await response.json();

        if (!response.ok) {
            console.log(json.error)
            setError(json.error)
        }
        if (response.ok) {
            // Save the user to local storage
            localStorage.setItem('user', JSON.stringify(json));

            // update the auth context
            dispatch({type: 'LOGIN', payload: json});
        }
    }
    return { guestSignup, error}
}