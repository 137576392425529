import { Box, Container, Typography, Divider, Link } from "@mui/material"

export default function Footer() {
    return (
        <Container maxWidth="lg"
        sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "100px"
        }}
        >
            <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
            <a href="https://www.partyapp.io/organizers">
                <Box
                sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    height: "80px",
                    justifyContent: "center"
                }}
                >
                    <Typography color="white">powered by</Typography>
                    <Box component="img" src="partyapp.png" sx={{ width: "110px" }}/>
                </Box>
            </a>
            <Box
            sx={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
            }}
            >
                    <Link sx={{color: "white", textDecoration: "none"}} href="./terms.pdf">Terms and Conditions</Link>
                    <Typography sx={{color: "white"}}>•</Typography>
                    <Link sx={{color: "white", textDecoration: "none"}} href="./privacy.pdf">Privacy Policy</Link>
            </Box>
        </Container>
    )
}