import { Container, CssBaseline, Typography, Box, CircularProgress } from "@mui/material"
import { Link } from "react-router-dom"

import { CheckCircle } from "@mui/icons-material"
import Navbar from "../components/Navbar"
import { useEffect, useState } from "react"

export default function SuccessPage() {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    },[])
    return(
        <>
            {!loading &&
            <>
                <Navbar/>
                <CssBaseline/>
                <Container maxWidth="lg" sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60vh", textAlign: "center", gap: "10px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <CheckCircle color="primary" sx={{fontSize: "70px"}}/>
                        <Typography fontWeight="600" color="primary" fontSize="40px">Thank you for your purchase!</Typography>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Typography color="white" fontSize="22px" textAlign="center">
                            You can view your purchases on the <Link to="/account">Account page</Link> or in the confirmation email we've sent you.
                        </Typography>
                    </Box>
                </Container>
            </>
            }
            {loading && 
                <>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", backgroundColor: "black"}}>
                        <CircularProgress/>
                    </Box>
                </>
            }
        </>
    )
}