import React from 'react'
import { Box, CssBaseline, Typography } from '@mui/material'
import Navbar from '../components/Navbar'

const NotFound = () => {
  return (
    <>
        <Navbar/>
        <CssBaseline/>
        <Box
        sx={{
            display: "flex", alignItems:  "center", justifyContent: "center", height: "30vh"
        }}
        >
            <Typography color="white" fontSize="24px"> <u>404: Not Found</u></Typography>
        </Box>
    </>
  )
}

export default NotFound
