import React from "react";
import { useState } from "react";
import { Typography, Alert, Container, Box, TextField, CssBaseline, Button, CircularProgress, Divider, Slide} from "@mui/material"
import { Link } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";

export default function Login() {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    const handleSubmit = async () => {
        setIsLoading(true)
        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/user/request-reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email})
        })
        const json = await response.json();
        if (response.ok) {
            setShowSuccess(true)
        } else {
            setError(json.error)
        }
        setIsLoading(false)
    }   

    return (  
        <>
            <CssBaseline/>
            {isLoading && 
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
               <CircularProgress/>
            </Box>}
            {!showSuccess && 
            <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",paddingLeft: "24px",
            paddingRight: "24px"}}>
                <Box sx={{height: "20vh"}}/>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "60vh", width: "90%", maxWidth: "400px",
                gap: "15px", backgroundColor: "black", padding: "30px", borderRadius: "20px"}}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", 
                        gap: "5px",
                        alignItems: "center"
                    }}>
                        <Link to="/">
                            <img height="65px" className="logo-nav" src="logo.png"></img>
                        </Link>
                        {/* <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center"
                        }}
                        >
                            <Typography color="white">powered by</Typography>
                            <Box component="img" src="partyapp.png" sx={{ width: "110px" }}/>
                        </Box> */}
                    </Box>
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            right: "12px",
                            zIndex: "100",
                            maxWidth: "90%"
                        }}
                    >           
                        <Slide direction="left" in={error != null}>
                            <Alert severity="error">{error}</Alert>
                        </Slide>
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Email Address 
                        </Typography>
                        <TextField value={email} type="email" onChange={e => setEmail(e.target.value)}
                            variant="outlined" placeholder="your@email.com" color="secondary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        />
                    </Box>
                    <Button color="secondary"
                    onClick={(e) => {handleSubmit(e)}}
                    sx={{color: "black", width: "100%", borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "white",
                        ":hover": {backgroundColor: "#BEC3CB"}}}
                    >
                        <span>Change Password</span>
                    </Button>
                    <Box sx={{display: "flex", gap: "6px"}}>
                        <Typography color="#D2D6DB" fontSize="14px">Don't have an account yet?</Typography>
                        <Typography component={Link} to="/signup" color="white" fontSize="14px">Sign Up</Typography>
                    </Box>
                    <Box sx={{display: "flex", gap: "6px"}}>
                        <Typography color="#D2D6DB" fontSize="14px">Remembered your password?</Typography>
                        <Typography component={Link} to="/login" color="white" fontSize="14px">Log In</Typography>
                    </Box>
                    <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                    <Box
                    sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    >
                        <Typography color="white">powered by</Typography>
                        <Box component="img" src="partyapp.png" sx={{ width: "110px" }}/>
                    </Box>
                </Box>
                <Box sx={{height: "20vh"}}/>
            </Container>
            }
            {showSuccess && 
            <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",paddingLeft: "24px",
            paddingRight: "24px", height: "100vh", gap: "10px"}}>
                <CheckCircle color="primary" sx={{fontSize: "70px"}}/>
                <Typography color="white" fontWeight="600">We sent you a link to reset your password over email.</Typography>
                <Typography component={Link} to="/" color="white">Back home</Typography>
                <Box
                sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                >
                    <Typography color="white">powered by</Typography>
                    <Box component="img" src="partyapp.png" sx={{ width: "110px" }}/>
                </Box>
            </Container>
            }
        </>  
    )
}