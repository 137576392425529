import { Box, Typography, ImageListItem } from "@mui/material"
import QRCode from "react-qr-code"

export default function Ticket({firstName, lastName, qrCode, name}) {
    return (
        <ImageListItem>
            <Box sx={{display: "flex", flexDirection: "column", height: "auto", gap: "10px", alignItems: "center", 
            border:"solid", borderColor: "#1a1c1f", borderRadius: "20px", padding: "50px"}}>
                <Box>
                <img height='44px' src="logo.png"></img>
                </Box>
                <Typography color="white" fontWeight="600" fontSize="28px">
                    {firstName} {lastName}
                </Typography>
                <Box sx={{display: "flex", flexDirection: "column", gap: "0px", alignItems:"center"}}>
                    {/* <Typography color="white" fontSize="22px">
                        {event}
                    </Typography> */}
                    <Typography color="white" fontSize="22px">
                        {name}
                    </Typography>
                </Box>
                <Box sx={{padding: "10px", backgroundColor: "white"}}>
                    <QRCode
                    value={qrCode}
                    />
                </Box>
                <Typography color="white" fontSize="12px">{qrCode}</Typography>
            </Box>
        </ImageListItem>
    )
}