import SignUpForm from "../components/SignupForm";
import { CssBaseline, Container  } from "@mui/material";
import Navbar from "../components/Navbar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SignUp(props) {
    const location = useLocation();
    let { redirectLink } = props
    if (location.state) {
        redirectLink = location.state.redirectLink
    }

    return (    
        <>
            <CssBaseline/>
            <Container maxWidth="md" sx={{height: "110vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
        marginTop: "50px",}}>
                <SignUpForm redirectLink={redirectLink}/>
            </Container>
        </>
    )
}