import { Box, Typography, Button, Dialog, DialogTitle, Divider } from "@mui/material"
import { Link } from "react-router-dom"
import { useState } from "react";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Navbar() {
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const [confirmLogout, setConfirmLogout] = useState(false);

    const handleConfirmLogout = () => {
        setConfirmLogout(true);
    }
    
    const handleCloseLogout = () => {
        setConfirmLogout(false);
    }

    const handleLogout = () => {
        handleCloseLogout();
        logout();
    }
    
    return (
        <Box
        sx={{
            display: "flex",
            justifyContent:"center", 
            width: "100%",
            paddingTop: "40px"
        }}
        >
            <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px"
            }}
            >
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    alignItems: "center"
                }}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", 
                        gap: "5px",
                        alignItems: "center"
                    }}>
                        <Link to="/">
                            <img height="65px" className="logo-nav" src="logo.png"></img>
                        </Link>
                    </Box>
                    {/* <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px"
                    }}
                    >
                    <Typography fontSize="12px" color="white">powered by</Typography>
                    <Typography fontSize="18px" fontWeight="600" color="white">PartyApp</Typography>
                    </Box> */}
                </Box>
                <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                <Box
                sx={{
                    display: "flex", gap: "12px", color: "white"
                }}
                >  
                    <Button component={Link} to="/" variant="text" sx={{textTransform: "none", color: "white"}}>
                        <Typography>Events</Typography>
                    </Button>
                    <Button component={Link} to="/account" variant="text" sx={{textTransform: "none", color: "white"}}>
                        <Typography>Account</Typography>
                    </Button>
                    {user && !user.isGuest &&
                    <Button onClick={handleConfirmLogout} variant="text" sx={{textTransform: "none", color: "white"}}>
                        <Typography>Log Out</Typography>
                    </Button>
                    }
                    {user && user.isGuest &&
                    <>
                        <Button component={Link} to='/login' variant="text" sx={{textTransform: "none", color: "white"}}>
                            <Typography>Login</Typography>
                        </Button>
                        <Button component={Link} to='/signup' variant="text" sx={{textTransform: "none", color: "white"}}>
                            <Typography>Sign Up</Typography>
                        </Button>
                    </>
                    }
                </Box>
            </Box>
            <Dialog
            open={confirmLogout}
            onClose={handleCloseLogout}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{backdropFilter: "blur(3px)"}}
            >
                <Box sx={{padding: "30px", backgroundColor: "black"}}>
                    <DialogTitle id="alert-dialog-title" sx={{color: "white"}}>
                        {"Are you sure you want to log out?"}
                    </DialogTitle>
                    <Box sx={{display: "flex", justifyContent: "center", gap: "5px"}}>
                        <Button variant="outlined" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", color:"#BEC3CB",
                        ":hover": {backgroundColor: "#43025F", color: "white"}}}
                            onClick={handleCloseLogout}>No</Button>
                        <Button 
                        sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "#7102a1", color:"#BEC3CB",
                        ":hover": {backgroundColor: "#43025F", color: "white"}}}
                        onClick={handleLogout} autoFocus>Yes</Button>
                    </Box>  
                </Box> 
            </Dialog>
        </Box>
    )
}