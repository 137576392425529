import { Box, CssBaseline, Button, CircularProgress, Slide, Alert} from '@mui/material'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useState } from 'react'


export default function AcceptTicketInvite() {
    const [searchParams] = useSearchParams()
    const inviteId = searchParams.get('inviteId')
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const acceptInvite = async function(inviteId) {
        setLoading(true)
        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/invite/guest/accept/ticket', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ inviteId })
        });
        const json = await response.json();
        if (response.ok) {
            navigate('/account', { replace: true });
            setLoading(false)
        } else {
            setError("Error while accepting the invitation")
            setLoading(false)
        }
    }


    return (
        <>
            <CssBaseline/>
            <Navbar/>
            {loading && <><Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}><CircularProgress/></Box></>}
            {!loading && <Box sx={{
                display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", minHeight: "60vh", justifyContent: "center"
            }}>
                <Button onClick={() => acceptInvite(inviteId)} mt={3} variant="contained" sx={{borderRadius: "20px", width: "300px", fontWeight: "600"}}>
                    Accept Invitation
                </Button>
            </Box>
            }
            <Box
            sx={{
                position: "fixed",
                bottom: "90px",
                left: "5px",
                zIndex: "100",
                maxWidth: "90%"
            }}
            >
                <Slide direction="right" in={error !== null}>
                    <Alert severity="error">{error}</Alert>
                </Slide>
            </Box>
            <Footer/>
        </>
    )
}