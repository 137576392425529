import { Box, Typography, ImageListItem, Button, CssBaseline, List, ListItem
} from "@mui/material"
import { TableBar as TableIcon, Person, AccessTime } from "@mui/icons-material"
import QRCode from "react-qr-code"
import { useState } from "react"
import { Navigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"


export default function Table({ person, table }) {
    const name = table.item.name
    const qrCode = table.qrCode
    const people = table.people
    const navigate = useNavigate();
    

    return (
        <>
        <CssBaseline/>
        <ImageListItem sx={{display: "flex", gap: "10px"}}>
            <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingLeft: "18px"
            }}
            >
                <Box sx={{
                    display: "flex",
                    gap: '5px',
                    alignItems: "center",
                    textTransform: "uppercase"
                }}>
                    {/* <TableIcon sx={{color: "white", fontSize: "20px"}}/> */}
                    <Typography color="white" fontSize="20px">{table.name}</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    gap: '5px',
                    alignItems: "center",
                    textTransform: "uppercase"
                }}>
                    <Person sx={{color: "white", fontSize: "20px"}}/>
                    <Typography color="white" fontSize="20px">
                        {people} people
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "0px",
                    gap: "5px"
                }}>
                    <Box sx={{
                        display: "flex",
                        gap: '5px',
                        alignItems: "center",
                        textTransform: "uppercase"
                    }}>
                        <AccessTime sx={{color: "white", fontSize: "20px"}}/>
                        <Typography color="white" fontSize="20px">
                            Table Pickup
                        </Typography>
                    </Box>
                    <List sx={{ listStyleType: 'disc', color: "white", paddingTop: "0px", paddingLeft: "25px", paddingBottom: "0px"}}>
                        <ListItem sx={{ display: 'list-item', paddingLeft: "0px", paddingTop: "0px" }}>
                            9:00-9:30 PM
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Button onClick={()=> navigate(`/modify?tableId=${table._id.toString()}&eventId=${table.eventId.toString()}`)} variant="contained" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "white", color:"black", width: 'auto',
            ":hover": {backgroundColor: "#BEC3CB", color: "black"}}}>
                <Typography fontWeight="600">Modify Reservation</Typography>
            </Button>
            <Box sx={{display: "flex", flexDirection: "column", height: "auto", gap: "20px", alignItems: "center", 
            border:"solid", borderColor: "#1a1c1f", borderRadius: "20px", padding: "50px"}}>
                <Box>
                    <img height='44px' src="logo.png"></img>
                </Box>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                >
                    
                    <TableIcon sx={{color: "white", fontSize: "50px"}}/>
                    <Typography color="white" fontWeight="600" fontSize="28px">
                        {name}
                    </Typography>
                </Box>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                >
                    <Typography color="white" fontWeight="600" fontSize="28px">
                        {person}
                    </Typography>
                </Box>
                {/* <Typography color="green" fontWeight="600" fontSize="28px">
                    {deposit}% Paid
                </Typography> */}
                <Box sx={{padding: "10px", backgroundColor: "white"}}>
                    <QRCode
                    value={qrCode}
                    />
                </Box>
                <Typography color="white" fontSize="12px">{qrCode}</Typography>
            </Box>
        </ImageListItem>
        </>
    )
}