import { Link, Navigate, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

export default function Partycard(props) {
    const navigate = useNavigate();
    let { eventName, date, hour, location, imageUrl, eventId } = props

    const [formattedDate, setFormattedDate] = useState();
    const formatDate = (date) => {
        date = new Date(date);  
        return setFormattedDate(date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));
    }

    useEffect(() => {
        if (date) {
            date = formatDate(date);
        }
    }, [])

    const handlesSelectParty = (eventId) => {
        navigate(`/party?eventId=${eventId}`);
    }
    return (
        <>  
            <Box 
            sx={{
                display: "flex", flexDirection: "column", gap: "7px"
            }}
            >
                {/* <Link to="/party"> */}
                    <Box sx={{
                        width: '300px',
                        height: '350px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }} onClick={() => handlesSelectParty(eventId)}/>
                {/* </Link> */}
                
                <Box 
                sx={{
                    display: "flex", flexDirection: "column", gap: "0px", alignItems: "center"
                }}
                >
                    <Typography color="white" fontSize="18px" fontWeight="600">{eventName}</Typography>
                    <React.Fragment>
                        <Typography color="#9CA3AF" fontSize="13px">{formattedDate} • {hour} - END</Typography>
                        <Typography color="#9CA3AF" fontSize="13px">{location}</Typography>
                    </React.Fragment>
                </Box>
            </Box>
        </>
    )
}