import { Typography, Box, CssBaseline } from "@mui/material";


export default function Secret() {
    return (
        <>
            <CssBaseline/>
            <Box sx={{
                display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", minHeight: "100vh", justifyContent: "center"
            }}>
                <img height="500px" className="logo-nav" src="s.jpeg"></img>
            </Box>
        </>
    )
}